<template>
	<div class="submit-information">
		<div class="set-information">
			<div class="info-item">
				<div class="info-title" style="margin-top: 0;">基本信息</div>
				<el-form  label-width="160px" style="padding-top: 30px;">
					<el-form-item label="注册手机号:" prop="RegisterPhone">
						<div>{{ruleForm.RegisterPhone}}</div>
					</el-form-item>
					<el-form-item label="联系人姓名:" prop="ContactName">
						<div>{{ruleForm.ContactName}}</div>
					</el-form-item>
					<el-form-item label="联系人手机号:" prop="ContactPhone">
						<div>{{ruleForm.ContactPhone}}</div>
					</el-form-item>
					<el-form-item label="电子邮箱:" prop="Email">
						<div>{{ruleForm.Email}}</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="info-item">
				<div class="info-title">商户信息</div>
				<div class="info-item-title">营业执照</div>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="商户名称:" prop="MerchantName">
						<div>{{ruleForm.MerchantName}}</div>
					</el-form-item>
					<el-form-item label="商户简称:" prop="MerchantAbbrName">
						<div>{{ruleForm.MerchantAbbrName}}</div>
					</el-form-item>
					<el-form-item label="统一社会信用代码:" prop="LicenseCode">
						<div>{{ruleForm.LicenseCode}}</div>
					</el-form-item>
					<el-form-item label="经营地址:" prop="AddressArr">
						<div>{{(ruleForm.BusinessAddressProvince||'') + (ruleForm.BusinessAddressCity||'') + (ruleForm.BusinessAddressDistrict||'') + (ruleForm.BusinessAddressDetail||'')}}</div>
					</el-form-item>
					<el-form-item label="商户电话:" prop="MerchantTel">
						<div>
							<span v-if="ruleForm.MerchantTelAreaCode">+{{ruleForm.MerchantTelAreaCode}}</span>
							{{ruleForm.MerchantTel}}
						</div>
					</el-form-item>
				</el-form>
				<div class="info-item-title" style="margin-top: 30px;">企业法人信息</div>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="法人姓名:" prop="CorporationName">
						<div>{{ruleForm.CorporationName}}</div>
					</el-form-item>
					<el-form-item label="法人手机号:" prop="CorporationPhone">
						<div>{{ruleForm.CorporationPhone}}</div>
					</el-form-item>
					<el-form-item label="法人证件号码:" prop="CorporationCardId">
						<div>{{ruleForm.CorporationCardId}}</div>
					</el-form-item>
					<el-form-item label="证件有效期:" prop="CorporationCardIdStartDate">
						<div>{{ruleForm.CorporationCardIdStartDate || ''}} ~ {{ruleForm.CorporationCardIdEndDate}}</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="info-item">
				<div class="info-title" style="margin-bottom: 20px;">结算信息</div>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="银行账户类型:" prop="CorporationName">
						<div>{{ruleForm.BankAccountType==1?'对公':'对私'}}</div>
					</el-form-item>
				</el-form>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="结算银行:" prop="CorporationName">
						<div>{{ruleForm.BankName}}</div>
					</el-form-item>
				</el-form>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="银行卡号:" prop="CorporationName">
						<div>{{ruleForm.CardNo}}</div>
					</el-form-item>
				</el-form>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="开户姓名:" prop="CorporationName">
						<div>{{ruleForm.CardHolderName}}</div>
					</el-form-item>
				</el-form>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="开户银行省份:" prop="CorporationName">
						<div>{{ruleForm.BankProvince}}</div>
					</el-form-item>
				</el-form>
				<el-form ref="ruleForm" label-width="160px">
					<el-form-item label="开户银行地区:" prop="CorporationName">
						<div>{{ruleForm.BankCity}}</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		
		<div class="submit">
			<el-button type="primary" @click="handleBack">返回</el-button>
		</div>
		
	</div>
</template>

<script>
	
	import {
		adapayMerchantInfo
	} from '@/api/TurnTomySelf.js'
	
	export default {
		components: {
		},
		data() {
			return {
				ruleForm: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData(){
				const res = await adapayMerchantInfo();
				
				if (res.IsSuccess){
					this.ruleForm = res.Result;
				}
			},
			handleBack() {
				this.$router.replace({
					name: 'settingPay'
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.submit-information {
		
		
		
		.title{
			flex: 0 0 auto;
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			background-color: #f8f8f9;
			font-size: 16px;
		}
		
		.set-information{
			flex: 1 1 auto;
			overflow: auto;
			padding-bottom: 100px;
			background-color: #fff;
			
			.info-item{
				// margin-left: 30px;
				margin-right: 40px;
				.info-title{
					height: 30px;
					line-height: 30px;
					padding-left: 20px;
					background-color: #f8f8f9;
					font-size: 14px;
					font-weight: bold;
					margin-top: 20px;
				}
			}
		}
		
		.info-item-title{
			height: 20px;
			line-height: 20px;
			padding-left: 5px;
			border-left: 3px solid #61afff;
			margin-left: 40px;
			margin-bottom: 20px;
			font-size: 15px;
			margin-top: 20px;
		}
		
		.submit{
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 60px;
			background-color: #fff;
			border-top: 1px solid #ddd;
			
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
	}
</style>
